/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <MKTypography variant="h2" fontWeight="bold" textAlign="center">
          Cin bir gelinle insan bir damadın, damadın babasının vasiyeti üzerine evlenmelerini anlatan trajik bir komedi
        </MKTypography>
      </Container>
    </MKBox>
  );
}

export default Information;

