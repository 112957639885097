/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, { useState, useEffect } from "react";
import RotatingCarb from "./sections/RotatingCarb";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
//import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import ButtonsGradient from "layouts/sections/elements/buttons/components/ButtonsGradient";
// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

import MKProgress from "components/MKProgress";

// Images
import bgImage from "assets/images/Saman/4.jpg";

function Presentation() {
  const [selectedButton, setSelectedButton] = useState("a");
  
  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 10 }}>
        {/* Other components */}
        <MKTypography
          variant="body1"
          color="text"
          fontWeight="bold"
          style={{ textAlign: "center", background: "white" }}
          >
          Bağış Hedefimiz
        </MKTypography>
        <MKProgress variant="contained" color="info" value={80000} value2={100000} label={true} />

        {/* Other components */}
      </div>
      <MKBox
        minHeight="50vh"
        height="10%"
        width="%10"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center 66%",
          backgroundRepeat: "no-repeat",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              ml={5}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                fontSize: "6rem", // You can adjust the size accordingly
                [breakpoints.down("md")]: {
                  fontSize: "4rem",
                },
              })}
            >
              Ş A M A N{" "}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          variant="transparent"
          bgColor="bright"
          shadow="sm"
          py={0.25}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MKBox py={0.25} display="flex" justifyContent="center" alignItems="center">
            <MKButton
              variant={selectedButton === "a" ? "gradient" : "outlined"}
              color="dark"
              onClick={() => handleButtonClick("a")}
              mx={2}
              py={0.5}
              style={{ margin: "0.5rem", flex: 1, width: "100px" }}
            >
              Hakkında
            </MKButton>
            <MKButton
              variant={selectedButton === "b" ? "gradient" : "outlined"}
              color="dark"
              onClick={() => handleButtonClick("b")}
              mx={2}
              py={0.5}
              style={{ margin: "0.5rem", flex: 1, width: "100px" }}
            >
              Bağış
            </MKButton>
          </MKBox>
        </MKBox>
        {selectedButton === "a" ? (
          <>
            <Information />
            <Counters />
            <DesignBlocks />
          </>
        ) : (
          <RotatingCarb />
        )}
      </Card>
    </>
  );
}

export default Presentation;
