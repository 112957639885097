import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import breakpoints from 'assets/theme/base/breakpoints';

function RotatingCardFront({ color, image, icon, title, description }) {
  const [myHeight, setMyHeight] = useState(1110);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > breakpoints.values.xxl) {
        setMyHeight(475);
      } else if (window.innerWidth > breakpoints.values.xl) {
        setMyHeight(500);
      } else if (window.innerWidth > breakpoints.values.lg) {
        setMyHeight(600);
      } else if (window.innerWidth > breakpoints.values.md) {
        setMyHeight(800);
      }
      else {
        setMyHeight(600);
      }
    };

    // Initial call to set initial values based on window size
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <MKBox
      display="flex"
      style={{ cursor: 'pointer' }}
      justifyContent="center"
      alignContent="center"
      borderRadius="lg"
      coloredShadow={color}
      width="100%"
      position="relative"
      zIndex={2}
      sx={{
        height: `${myHeight}px`,
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0),
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0)
          )}, url(${image})`,
        backgroundSize: 'cover',
        backfaceVisibility: 'hidden',
      }}
    >
      <MKBox py={12} px={3} textAlign="center" lineHeight={1}>
        {icon && (
          <MKTypography variant="h2" color="white" my={2}>
            {typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}
          </MKTypography>
        )}
        <MKTypography variant="h3" color="white" gutterBottom>
          {title}
        </MKTypography>
        <MKTypography variant="body2" color="white" opacity={0.8} fontWeight="bold">
          {description}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

RotatingCardFront.defaultProps = {
  color: 'info',
  icon: '',
};

RotatingCardFront.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  image: PropTypes.string.isRequired,
  icon: PropTypes.node,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
};

export default RotatingCardFront;
