/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import InstagramIcon from "@mui/icons-material/Instagram";

function DesignBlocks() {
  const instagramLink = "https://www.instagram.com/samankisafilm"; // Replace with your actual Instagram account link
  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <MKTypography variant="body1" color="text" style={{ textAlign: "justify" }}>
          İlyas’ın babası Vahdet ölüm döşeğindeyken cenazesinin kırkı çıkar çıkmaz Burçin ve
          İlyas’ın elalemin tepkisine aldırmadan evlenmelerini vasiyet etmiştir. İkili onun bu
          dileğini yerine getirir fakat düğün gecesi gergin bir şekilde son hazırlıklarını yaparken
          İlyas’ın bu evliliğe karşı çıkan annesi Gülizar’ın düğüne gelmesiyle sarsılırlar. İlyas
          soğukkanlılığını korur ama Burçin strese yenik düşer ve düğünün açılışında ilk dansta
          donakalır, hareket edemez. Düğünün Burçin için bir fiyaskoyla açılması, İlyas’ın
          akrabalarının Burçin’e ve ailesine karşı önyargılarını güçlendirir. Burçin her ne kadar
          iyi niyetini korumaya çalışsa da düğüne gelen misafirler, özellikle İlyas’ın ailesinin
          erkek büyükleri onu iyice sıkıştırır ve onunla alay eder. Burçin kendi düğününden kaçmaya
          çalışır, ama annesi Perihan onu geri gelmeye zorlar. Döndüğünde bu sefer de İlyas’ın
          annesi Gülizar tarafından zorbaca bir tutumla karşılanan Burçin, İlyas’ın da katılmasıyla
          büyüyen kavga esnasında kendini daha fazla tutamaz ve patlar. Büyük ve etkileyici bir
          şaman ritüeli yapmaya başlayarak gitgide sarpa saran düğününü kontrol altına alır. Büyüyle
          İlyas’ın üzerine giderek onu ve ailesini Vahdet’in ölümüyle yüzleştirir. Aslında şenlik
          olması gereken düğünü bir yas merasimine çevirir, Vahdet’in ruhunu çağırır.
        </MKTypography>
      </Container>
      <Container>
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <MKBox
            bgColor="light"
            borderRadius="xl"
            marginTop="2rem"
            shadow="lg"
            minHeight="5rem" // Adjust the minHeight as needed
            sx={{
              overflow: "hidden",
              width: "200px", // Adjust the width as needed
              transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
              transformOrigin: "50% 0",
              backfaceVisibility: "hidden",
              willChange: "transform, box-shadow",
              transition: "transform 200ms ease-out",
              fontWeight: "bold", // Adjust the fontWeight as needed

              "&:hover": {
                transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
              },
            }}
          >
            <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
              <InstagramIcon
                style={{ transform: "scale(1.8)", marginRight: "10px", marginTop: "10px" }}
              />
              <span style={{ marginLeft: "10px" }}>Bizi buradan takip edin</span>
            </div>
          </MKBox>
        </a>
      </Container>
    </MKBox>
  );
}

export default DesignBlocks;
