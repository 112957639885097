/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Carousel from "react-material-ui-carousel";
import { PrevButton, NextButton } from "react-material-ui-carousel";
import "./styles/Item.css"; // Import your CSS file

import img1 from "assets/images/Saman/1.jpg";
import img2 from "assets/images/Saman/2.jpg";
import img3 from "assets/images/Saman/3.jpg";
import img5 from "assets/images/Saman/5.jpg";

import { Paper } from "@mui/material";

import PropTypes from "prop-types";

function Counters() {
  var items = [
    {
      type: "video",
      videoUrl: "https://drive.google.com/file/d/1_pGiOk7roDQe93VAwQsxnIQDG-wKtSfY/preview", // Replace with your actual video URL or ID
    },
    {
      type: "image",
      imageUrl: img1,
    },
    {
      type: "image",
      imageUrl: img2,
    },
    {
      type: "image",
      imageUrl: img3,
    },
    {
      type: "image",
      imageUrl: img5,
    },
  ];

  return (
    <Carousel
      interval={null}
      PrevButton={(props) => <PrevButton {...props} />}
      NextButton={(props) => <NextButton {...props} />}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  const { item } = props;

  return (
    <Paper className="carousel-item">
      {item.type === "image" ? (
        <div className="image-container" style={{ backgroundImage: `url(${item.imageUrl})` }}></div>
      ) : (
        <iframe
          title="video"
          width="100%"
          height="100%"
          src={item.videoUrl}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          autoPlay
        ></iframe>
      )}
    </Paper>
  );
}

Item.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    videoUrl: PropTypes.string,
  }).isRequired,
};

export default Counters;
