/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Custom styles
import MKProgressRoot from "components/MKProgress/MKProgressRoot";

const MKProgress = forwardRef(({ variant, color, value, label, value2, ...rest }, ref) => (
  <div style={{ position: "relative" }}>
    <MKProgressRoot
      {...rest}
      ref={ref}
      variant="determinate"
      value={value}
      value2={value2}
      ownerState={{ color, value, value2, variant }}
    />
    {label && (
      <MKTypography
        variant="button"
        fontWeight="bold"
        color="text"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 15,
          fontSize: "20px", // Adjust the font size as needed
        }}
      >
        {value} ₺ / {value2} ₺
      </MKTypography>
    )}
  </div>
));

MKProgress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  value2: 15,
  label: false,
};

MKProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.bool,
};

export default MKProgress;
