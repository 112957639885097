/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "components/MKTypography";
import FormIcon from '@mui/icons-material/Assignment';


import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";

// Images
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

import iban200 from "assets/images/qrs/200.jpeg";
import iban500 from "assets/images/qrs/500.jpeg";
import iban1000 from "assets/images/qrs/1000.jpeg";
import iban2500 from "assets/images/qrs/2500.jpeg";
import iban5000 from "assets/images/qrs/5000.jpeg";
import iban10000 from "assets/images/qrs/10000.jpeg";
import pararka from "assets/images/Saman/pararka.jpg";

function RotatingCarb() {
  const iban = "TR90 0006 7010 0000 0055 2155 34";

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(iban);
      console.log("IBAN copied to clipboard:", iban);
    } catch (err) {
      console.error("Unable to copy IBAN to clipboard", err);
    }
  };

  return (
    <Container>
      <MKBox component="section" py={6} my={6}>
        <Container>
          <MKTypography
            variant="h2"
            color="black"
            mt={0}
            mb={10}
            ml={0}
            mr={3.5}
            sx={({ breakpoints, typography: { size } }) => ({
              fontSize: "1.5rem", // You can adjust the size accordingly
              textAlign: 'justify', // Add this line to justify the text
              [breakpoints.down("lg")]: {
                fontSize: size["lg"],
              },
              [breakpoints.down("md")]: {
               textAlign: 'left',
              },
            })}
          >
            Filmimizi hangi kategoriden desteklemeyi seçerseniz o ve ondan önceki tüm kategorilerin
            ödüllerini kazanırsınız. Lütfen bağış yaptıktan sonra
            sponsor formunu doldurmayı unutmayın. Destekleriniz için çok teşekkür ederiz.{" "}
          </MKTypography>
          <MKBox
            display="flex"
            alignItems="center"
            p={2}
            ml={3.5}
            mr={3.5}
            mb={1}
            border={1}
            borderRadius={4}
          >
            <MKBox flexGrow={1}>
              <span>{iban}</span>
            </MKBox>
            <IconButton onClick={handleCopyToClipboard} color="inherit">
              <FileCopyIcon style={{ color: "black" }} />
            </IconButton>
          </MKBox>
          <MKBox
            display="flex"
            alignItems="center"
            p={2}
            ml={3.5}
            mr={3.5}
            mb={1}
            border={1}
            borderRadius={4}
          >
            <MKBox flexGrow={1}>
              <span>Ege Eren</span>
            </MKBox>
          </MKBox>
          <MKBox
            display="flex"
            alignItems="center"
            p={2}
            ml={3.5}
            mr={3.5}
            mb={10}
            border={1}
            borderRadius={4}
          >
            <MKBox flexGrow={1}>
            <a href="https://forms.gle/D8AqgDv6SPVybPedA" target="_blank" rel="noopener noreferrer">
          <FormIcon style={{ marginRight: '4px' }} /> Sponsor Formu
        </a>
            </MKBox>
          </MKBox>
          

          <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <RotatingCard>
                  <RotatingCardFront
                    image={pararka}
                    icon="touch_app"
                    title={<>200 ₺</>}
                    description={"Filmin izleme linki"}
                  />
                  <RotatingCardBack image={iban200} />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <RotatingCard>
                  <RotatingCardFront
                    image={pararka}
                    icon="touch_app"
                    title={<>500 ₺</>}
                    description="+ Setten fotoğraflar, eskiz çalışmaları, görsel senaryo taslağı, filmin yüksek çözünürlüklü posterleri (hepsi dijital)"
                  />
                  <RotatingCardBack image={iban500} />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <RotatingCard>
                  <RotatingCardFront
                    image={pararka}
                    icon="touch_app"
                    title={<>1.000 ₺</>}
                    description="+ Sosyal medya hesabımızdan adınıza teşekkürler"
                  />
                  <RotatingCardBack image={iban1000} />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <RotatingCard>
                  <RotatingCardFront
                    image={pararka}
                    icon="touch_app"
                    title={<>2.500 ₺</>}
                    description="+ Filmin kapanış jeneriğinde destekleriniz için adınıza özel teşekkürler"
                  />
                  <RotatingCardBack image={iban2500} />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <RotatingCard>
                  <RotatingCardFront
                    image={pararka}
                    icon="touch_app"
                    title={<>5.000 ₺</>}
                    description="+ Film yapım ve oyuncu ekibinden size özel olarak çekilecek bir video.

                  Videoda dilerseniz bir paragraf içinde yazacağınız bir mesajı sevdiklerinize göndermeniz için okuyup kaydedebiliriz veya ekibimizin bu kategoride bağış yapan herkese ayrı ayrı hazırlayacağı sürpriz içeriklerden birine sahip olabilirsiniz.
                  "
                  />
                  <RotatingCardBack image={iban5000} />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <RotatingCard>
                  <RotatingCardFront
                    image={pararka}
                    icon="touch_app"
                    title={<>10.000 ₺</>}
                    description="+ Filmin ortak yapımcılarından (co-producer) olma hakkı ve jenerikte bu sıfatınızla birlikte size özel bir kart"
                  />
                  <RotatingCardBack image={iban10000} />
                </RotatingCard>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </Container>
  );
}

export default RotatingCarb;
